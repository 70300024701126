import React from 'react';
import { Link, Paragraph, PlatformTab } from 'docComponents';

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <Paragraph>
        Head over to the{' '}
        <Link href="/components/icon" isDesignCodeLink>
          icon
        </Link>{' '}
        component to add icons to your interface.
      </Paragraph>
    </PlatformTab>
  );
};

export default ReactNativeTab;
